<script setup lang="ts">
import NewsSidebar from "~/components/Sidebars/NewsSidebar.vue";
import Paychecks from "~/components/Paycheck/Paychecks.vue";
import { setTokens } from "~/utils/authHelpers.js";
import { initializeAmplitude } from "~/utils/analytics";

const defaultStore = useDefaultStore();
const loading = ref(true);
const route = useRoute();

const user = computed(() => {
  return defaultStore.user;
});

const notificationSidebarOpen = computed(() => {
  return defaultStore.notificationSidebarOpen;
});

watch(
  () => route.fullPath,
  () => {
    defaultStore.closeNotificationSidebar();
    defaultStore.refreshNotifications();
  }
);

watch(
  user,
  (newUser, oldUser) => {
    if (!oldUser?.userId && newUser?.userId) {
      initData();
    }
  },
  {
    immediate: true,
  }
);

function initData() {
  defaultStore.refreshNotifications();
  defaultStore.loadPaychecks();
}

function getAccessToken() {
  const routeAccessToken = route.query?.access_token ?? "";
  if (routeAccessToken) {
    return routeAccessToken;
  }

  if (localStorage.getItem("access_token")) {
    return localStorage.getItem("access_token");
  }
}

function getRefreshToken() {
  const refreshToken = route.query?.refresh_token ?? "";

  if (refreshToken) {
    return refreshToken;
  }
  if (localStorage.getItem("refresh_token")) {
    return localStorage.getItem("refresh_token");
  }
}

function getSessionToken() {
  const refreshToken = route.query?.session_token ?? "";

  if (refreshToken) {
    return refreshToken;
  }
  if (localStorage.getItem("session_token")) {
    return localStorage.getItem("session_token");
  }
}


onMounted(() => {
  initializeAmplitude();

  const accessToken = getAccessToken();
  const refreshToken = getRefreshToken();
  const sessionToken = getSessionToken();

  useRouter().replace({ query: {} });

  if (accessToken && refreshToken) {
    setTokens(accessToken, refreshToken, sessionToken);

    defaultStore.loadUser().finally(() => {
      loading.value = false;
    });
  } else {
    loading.value = false;
  }
});
</script>

<template>
  <PageLoader v-if="loading" />
  <EvangelistLanding v-else-if="!user.userId" />
  <div v-else class="text-sm font-semibold">
    <div style="min-height: 100vh">
      <AppHeader />
      <div class="mt-2 lg:mt-4">
        <NuxtPage />
      </div>
      <Sidebar
        v-if="notificationSidebarOpen"
        @close="defaultStore.closeNotificationSidebar"
      >
        <NewsSidebar />
      </Sidebar>
    </div>
    <Paychecks />
    <GlobalTweetDetailSidebar />
    <GlobalFtuModal />
    <GlobalWithdrawalOtpModal />
    <GlobalVerifyEmailOtpModal />
    <GlobalVerifyTfaOtpModal />
    <GlobalNewPostModal />
    <GlobalEligibilityPassedModal />
    <GlobalCampaignDetailSidebar />
    <GlobalFAQ />
  </div>
</template>
